.blocCards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .displayCards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 400px;
    border-radius: 15px;
    border: solid 2px var(--textColor);
    &:hover {
      box-shadow: 0px 5px 0px 0px;
      color: var(--textColor);
      transition: ease 0.2s;
      &:hover .buttonPos {
        box-shadow: 0px 1px 0px 0px;
        color: var(--textColor);
        transition: ease 0.2s;
      }
    }
    .cardsScreen {
      width: 220px;
      height: 320px;
      background-color: var(--mainColor);
      border: solid 2px var(--textColor);
      border-radius: 15px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      .contentCard {
        margin-top: 15px;
        margin-left: 10px;
        .contentSection {
          width: 90%;
          .intituleCard {
            font-size: 10px;
            font-family: $cascadia;
          }
          .spanCards {
            font-size: 12px;
            color: var(--textColor);
            text-align: justify;
          }
          .spanCardsLangType {
            font-size: 15px;
            color: var(--textColor);
            font-weight: 700;
          }
          .spanCardsPoste {
            font-size: 12px;
            color: var(--textColor);
            font-weight: 700;
          }
        }
      }
    }
    .buttonPos {
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 767px) {
  .blocCards {
    .displayCards {
      transform: scale(90%);
    }
  }
}
@media screen and (max-width: 500px) {
  .blocCards {
    > a {
      max-width: 250px;
    }
  }
}
@media screen and (max-width: 360px) {
  .blocCards {
    .displayCards {
      transform: scale(100%);
    }
  }
}
