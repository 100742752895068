header {
  .navBarBurger {
    position: fixed;
    display: flex;
    align-items: center;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 999;
    &.hasBackground {
      background-color: var(--mainColor);
    }
    .burgerBar {
      width: 24px;
      height: 3px;
      background-color: var(--textColor);
      transition: background-color 0.3s ease;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 24px;
        height: 3px;
        left: 6px;
        background-color: var(--textColor);
        transition: transform 0.3s ease;
      }

      &:before {
        transform: translateY(-8px);
      }

      &:after {
        transform: translateY(8px);
      }
      &.open {
        background-color: transparent;

        &:before {
          transform: translateY(0) rotate(45deg);
        }

        &:after {
          transform: translateY(0) rotate(-45deg);
        }
      }
    }
  }
}
.globalNav {
  z-index: 3;
  display: grid;
  grid-template-rows: 25% 50% auto 40px;
  background-color: $secondaryColor;
  min-width: 250px;
  height: 100dvh;
  position: fixed;
  .logoPorfolio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
    margin-top: 185px;
    margin-bottom: 130px;
  }
  .navMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    .ulNav {
      padding: 0;
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 45px;
      .liNav {
        background-color: var(--mainColor);
        border-radius: 15px;
        &:hover {
          box-shadow: 0px 5px 0px 0px rgba($color: $pinkColor, $alpha: 1);
          background-color: var(--mainColor);
          transition: ease 0.2s;
        }
        .navButton {
          all: unset;
          background-color: var(--mainColor);
          border-radius: 15px;
          width: 120px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .lienNav {
          font-family: $baloo;
          font-size: 15px;
          color: var(--textColor);
          text-decoration: none;
        }
      }
    }
  }
  .copyRight {
    color: $textColor;
    font-family: $balooT;
    font-size: 12px;
    align-self: end;
    grid-row: 4;
    text-align: center;
    justify-self: center;
    margin-bottom: 10px;
    line-height: 1;
    .portfolioVersion {
      color: $textColor;
      line-height: 1;
      font-size: 9px;
    }
  }
  .iconsRS {
    display: flex;
    position: fixed;
    top: 70px;
    right: 100px;
    width: 92px;
    z-index: 2;
    gap: 12px;

    .iLinkedin {
      width: 40px;
      height: 40px;
    }
    .iGithub {
      width: 40px;
      height: 40px;
    }
  }
}
@media (max-height: 600px) and (orientation: landscape) {
  header .globalNav {
    width: 100%;
    height: 100%;
    grid-template-rows: 20% 1fr auto auto;
  }

  .globalNav .navMenu .ulNav {
    gap: 10px;
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 992px) {
  header {
    .globalNav {
      z-index: 3;
      height: 100dvh;
      width: 25%;
      display: grid;
      justify-self: center;
      position: fixed;
      background-color: $secondaryColor;
      &.hidden {
        display: none;
        transition: all 0.8s ease;
      }
      .logoLink {
        display: grid;
        .logoPorfolio {
          display: grid;
          justify-self: center;
          align-self: center;
          width: 40%;
          margin: 0;
        }
      }
      .navMenu {
        height: 100%;
        .ulNav {
          padding: 0;
          margin: 0;
          display: grid;
          .liNav {
            border-radius: 25px;
            .navButton {
              width: 175px;
              height: 40px;
              border-radius: 20px;
            }
            .lienNav {
              font-size: 20px;
            }
          }
        }
      }
      .navBarBurger {
        width: 40px;
        height: 30px;
        visibility: visible;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: fixed;
        top: 1rem;
        left: 1rem;
        cursor: pointer;
        .burgerBar {
          width: 100%;
          height: 4px;
          border-radius: 25px;
          background-color: black;
        }
      }
      .iconsRS {
        position: unset;
        justify-self: center;
        align-self: center;
      }
      .copyRight {
        justify-self: center;
        align-self: end;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 768) {
  header {
    .globalNav {
      width: 75%;
    }
  }
}
@media screen and (max-width: 500px) {
  header {
    .globalNav {
      width: 100%;
    }
  }
}
