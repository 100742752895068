.contact {
  grid-column: 1;
  position: relative;
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: 30% auto 1fr;
  height: 100dvh;
  z-index: 1;
  .titreContact {
    width: 315px;
    grid-column: 2;
    grid-row: 2;
    margin-bottom: 50px;
    .spanNameTrace {
      display: inline-block;
      position: relative;
      z-index: 1;
      .traitContact {
        position: absolute;
        bottom: 5px;
        right: 0;
        z-index: -1;
      }
    }
  }
  .contentContactDisplay {
    grid-column: 2;
    grid-row: 3;
    display: grid;
    column-gap: 50px;
    grid-template-columns: auto 1fr;
    .contactInfos {
      width: 430px;
      .spanInfosContact {
        font-weight: 600;
      }
      .separationBarre {
        width: 90%;
        margin: 50px 0;
      }
    }
    .contactForm {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-wrap: wrap;
      .sendButtonContact {
        margin-right: 140px;
        align-self: center;
      }

      grid-column: 2;
    }
  }
}
@media screen and (max-width: 992px) {
  .contact {
    grid-column: 1;
    grid-template-columns: 1fr;
    grid-template-rows: 25% auto 1fr;
    column-gap: 0;
    row-gap: 0;
    .titreContact {
      display: inline-block;
      grid-column: 1;
      margin-left: 50px;
    }
    .contentContactDisplay {
      width: 90%;
      grid-column: 1;
      margin: 0 auto;
      column-gap: 20px;
      .contactInfos {
        max-width: 100%;
      }
      .contactForm {
        max-width: 100%;
        .sendButtonContact {
          margin-right: 0;
          align-self: end;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .contact {
    grid-template-rows: 10% auto 1fr;
    row-gap: 35px;
    .titreContact {
      width: 90%;
      margin: auto;
    }
    .contentContactDisplay {
      grid-template-columns: 1fr;
      grid-column: 1;
      width: 90%;
      margin: 0 auto;
      row-gap: 0;
      .contactInfos {
        max-width: 100%;
        justify-self: center;
      }
      .contactForm {
        justify-self: center;
        grid-column: 1;
        grid-row: 2;
        width: 80%;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .contact {
    row-gap: 0;
    max-width: 360px;
    .titreContact {
      max-width: 360px;
    }
    .contentContactDisplay {
      width: 360px;
      .contactInfos {
        width: 90%;
        margin: auto;
        .separationBarre {
          width: 100%;
        }
      }
      .contactForm {
        max-width: 360px;
        width: 90%;
        margin: auto;
        .formDisplay {
          width: 90%;
          margin: auto;
        }
      }
    }
  }
}
