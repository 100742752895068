.blocFunFact {
  text-align: justify;
  width: calc(700px / 3);

  .number {
    margin-bottom: 15px;
    font-size: 55px;
    height: 50px;
  }
  .textZero {
    font-size: 14px;
  }
  .textGras {
    font-size: 14px;
  }
  .textDeux {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .blocFunFact {
    max-width: 100%;
    text-align: left;
    line-height: 1.2;
    .number {
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 500px) {
  .blocFunFact {
    width: 100%;

    .number {
      margin-bottom: 0;
      font-size: 48px;
    }
  }
}
