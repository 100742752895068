.divImg {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 520px;
  .imgPerso {
    height: 100%;
  }
  .biberon {
    position: absolute;
    top: 100px;
    right: 40px;
    animation: imgAnimationPerso 2.5s ease-in-out infinite alternate;
    transform: rotateZ(7deg);
  }
  .manette {
    position: absolute;
    bottom: 30px;
    right: 20px;
    animation: imgAnimationPerso 2.5s ease-in-out 0.5s infinite alternate;
    transform: translateX(-5px);
  }
  .micro {
    position: absolute;
    top: 110px;
    left: 30px;
    animation: imgAnimationPerso 2.8s ease-in-out 0.7s infinite alternate;
    transform: rotateZ(-5deg);
  }
  .masque {
    position: absolute;
    bottom: 30px;
    left: 10px;
    animation: imgAnimationPerso 2.2s ease-in-out 0.2s infinite alternate;
    transform: translateX(15px);
  }
}
@media screen and (max-width: 500px) {
  .divImg {
    .biberon {
      scale: 0.5;
      top: 0;
      right: -20px;
    }
    .manette {
      scale: 0.45;
      bottom: 10px;
      right: -30px;
    }
    .micro {
      scale: 0.5;
      top: 10px;
      left: -30px;
    }
    .masque {
      scale: 0.5;
      bottom: 10px;
      left: -30px;
    }
  }
}
