.experienceDiv {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 500px;
  margin-bottom: 20px;
  .experienceDate {
    font-size: 44px;
    line-height: 1;
    .dateDebut {
      color: var(--textColor);
      opacity: 0.1;
    }
    .dateFin {
      color: var(--textColor);
      height: 35px;
    }
  }
  .titreExperience {
    position: relative;
    display: inline-block;
    line-height: 1;
    z-index: 1;
  }
  .experienceDescription {
    text-align: justify;
  }
}
@media screen and (max-width: 992px) {
  .experienceDiv {
    max-width: 100%;
    .experienceDate {
      font-size: 36px;
    }
  }
}
@media screen and (max-width: 767px) {
  .experienceDiv {
    .titreExperience {
      display: block;
    }
  }
}
@media screen and (max-width: 500px) {
  .experienceDiv {
    flex-direction: column;
    gap: 10px;
    .titreExperience {
      display: inline-block;
    }
  }
}
