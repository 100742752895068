.about {
  grid-column: 1;
  justify-self: center;
  .divImg {
    margin-top: 50px;
    display: flex;
    align-items: flex-end;
    height: 520px;
  }
  .imgPerso {
    height: 100%;
  }
  .imgPro {
    height: 100%;
  }
  .toggleSetting {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .transparentText {
    font-size: 20px;
    font-weight: 800;
    color: var(--textColor);
    opacity: 0.1;
  }
  .label {
    font-size: 20px;
    font-family: $balooT;
    font-weight: bolder;
    color: var(--textColor);
  }
  .grasPerso {
    font-weight: 800;
    color: $pinkColor;
  }
  .grasPro {
    font-weight: 800;
    color: $purpleColor;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .about {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-column: 1;
  }
}
@media screen and (max-width: 500px) {
  .about {
    .divImg {
      margin-top: 0;
      align-items: center;
      height: 400px;
      .imgPro {
        height: 85%;
      }
      .imgPerso {
        height: 85%;
      }
    }
    .toggleSetting {
      margin-top: 0;
    }
  }
}
@media screen and (max-width: 500px) {
  .about {
    .divImg {
      margin-top: 0;
      align-items: center;
      height: 300px;
      .imgPro {
        height: 80%;
      }
      .imgPerso {
        height: 80%;
      }
    }
    .toggleSetting {
      margin-top: 0;
    }
  }
}
