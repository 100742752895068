.pagePerso {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  width: 100%;
  padding-bottom: 115px;
  .textAPropos {
    grid-column: 1;
    justify-self: center;
    width: 800px;
    margin-top: 50px;
  }
  .titreFunFacts {
    grid-column: 1;
    grid-row: 2;
    justify-self: center;
    width: 800px;
    display: flex;
    margin: 20px 0 10px 0;
    justify-content: flex-start;
    .spanNameTrace {
      display: inline-block;
      position: relative;
      z-index: 1;
      .traitTitre {
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 2px;
      }
    }
  }
  .funFactsBlocs {
    grid-column: 1;
    grid-row: 3;
    justify-self: center;
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 50px;
  }
}
@media screen and (max-width: 992px) {
  .pagePerso {
    grid-template-columns: 100%;
    .textAPropos {
      grid-column: 1;
      justify-self: center;
    }
    .titreFunFacts {
      grid-column: 1;
    }
    .funFactsBlocs {
      grid-column: 1;
      margin-bottom: 50px;
    }
  }
}
@media screen and (max-width: 767px) {
  .pagePerso {
    .textAPropos {
      width: 80%;
      margin: 20px auto;
    }
    .titreFunFacts {
      width: 80%;
      margin: auto;
      margin-bottom: 20px;
    }
    .funFactsBlocs {
      width: 80%;
      grid-template-columns: repeat(2, auto);
      row-gap: 20px;
      margin: auto;
    }
  }
}
@media screen and (max-width: 500px) {
  .pagePerso {
    grid-template-columns: 100vw;
    justify-self: center;
    align-self: center;
    .textAPropos {
      width: 80%;
    }
    .titreFunFacts {
      margin: auto;
      margin-bottom: 20px;
    }
    .funFactsBlocs {
      grid-template-columns: 100%;
      justify-self: center;
    }
  }
}
