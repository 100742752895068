.divImg {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 520px;
  .imgPerso {
    height: 100%;
  }
  .crochets {
    position: absolute;
    top: 75px;
    right: 200px;
    animation: imgAnimationPro 2.5s ease-in-out 0.2s infinite alternate;
    transform: translate3d(-5px, 10px, 0);
  }
  .guillemets {
    position: absolute;
    top: 200px;
    right: 40px;
    animation: imgAnimationPro 1.8s ease-in-out 0.7s infinite alternate;
    transform: rotateZ(-30deg);
  }
  .accolades {
    position: absolute;
    top: 135px;
    right: 110px;
    animation: imgAnimationPro 2s ease-in-out 0.5s infinite alternate;
    transform: translate3d(5px, 10px, 0) scale(90%);
  }
  .etoileTop {
    position: absolute;
    top: 65px;
    left: 120px;
    animation: imgAnimationPro 1.5s ease-in-out 0.5s infinite alternate;
    transform: rotate(25deg) scale(80%);
  }
  .etoile {
    position: absolute;
    top: 230px;
    left: 160px;
    animation: imgAnimationPro 1.5s ease-in-out 0.9s infinite alternate;
    transform: rotate(-35deg) scale(80%);
  }
  .cerclePro {
    position: absolute;
    bottom: 140px;
    left: 20px;
    animation: imgAnimationPro 2s ease-in-out 0.4s infinite alternate;
    transform: translate3d(5px, -10px, 0);
  }
}
@media screen and (max-width: 500px) {
  .divImg {
    .crochets {
      scale: 0.5;
      top: 50px;
      right: 65px;
    }
    .guillemets {
      scale: 0.5;
      top: 125px;
      right: 0;
    }
    .accolades {
      scale: 0.5;
      top: 80px;
      right: 25px;
    }
    .etoileTop {
      scale: 0.5;
      top: 50px;
      left: 50px;
    }
    .etoile {
      scale: 0.5;
      top: 130px;
      left: 70px;
    }
    .cerclePro {
      scale: 0.5;
      bottom: 60px;
      left: 0;
    }
  }
}
