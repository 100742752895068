@font-face {
  font-family: "Baloo";
  src: url("./assets/fonts/BalooBhai-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Cascadia";
  src: url("./assets/fonts/Cascadia.ttf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

$baloo: "Baloo", sans-serif;
$balooT: "Baloo 2", sans-serif;
$cascadia: "Cascadia", sans-serif;

$mainColor: #fef6e4;
$textColor: #0e103d;
$secondaryColor: #adc4fe;
$pinkColor: #faccd5;
$purpleColor: #dfccee;

$mainColorDark: #0e103d;
$textColorDark: #fef6e4;
$secondaryColorDark: rgba(173, 196, 254, 0.3);
$pinkColorDark: rgba(250, 204, 213, 0.3);
$purpleColorDark: rgba(224, 203, 238, 0.3);
:root,
[data-theme="light"] {
  --mainColor: #{$mainColor};
  --secondaryColor: #{$secondaryColor};
  --textColor: #{$textColor};
  --pinkColor: #{$pinkColor};
  --purpleColor: #{$purpleColor};
}
[data-theme="dark"] {
  --mainColor: #0e103d;
  --textColor: #{$textColorDark};
  --secondaryColor: #{$secondaryColorDark};
  --pinkColor: #{$pinkColorDark};
  --purpleColor: #{$purpleColorDark};
}

html,
body {
  z-index: -2;
  margin: 0;
  padding: 0;
  height: 100dvh;
  ::-webkit-scrollbar {
    display: none;
  }
  .layout {
    align-items: flex-start;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 250px 1fr;

    .globalDisplay {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
#App {
  background-color: var(--mainColor);
}
@media screen and (max-width: 992px) {
  html,
  body {
    .layout {
      grid-template-columns: 1fr;
      width: 100%;
      .globalDisplay {
        display: grid;
        grid-template-columns: 1fr;
        width: 100vw;
        min-height: 100vh;
      }
    }
  }
}

h1 {
  color: var(--textColor);
  font-family: $baloo;
  font-weight: 400;
  font-size: 52px;
  margin: 0;
  padding: 0;
  z-index: 1;
}
h2 {
  color: var(--textColor);
  font-family: $balooT;
  font-weight: 400;
  font-size: 44px;
  margin: 0;
  padding: 0;
}
h3 {
  color: var(--textColor);
  font-family: $balooT;
  font-weight: 400;
  font-size: 36px;
  margin: 0;
  padding: 0;
}
h4 {
  color: var(--textColor);
  font-family: $balooT;
  font-size: 28px;
  margin: 0;
  padding: 0;
}
h5 {
  color: var(--textColor);
  font-family: $balooT;
  font-size: 22px;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.baloo {
  font-family: $baloo;
  font-weight: 400;
}
.balooT {
  font-family: $balooT;
  font-weight: 400;
}
span {
  color: var(--textColor);
}
p {
  color: var(--textColor);
  font-size: 16px;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.colorBlue {
  color: $secondaryColor;
}
.colorPurple {
  color: $purpleColor;
}
.colorPink {
  color: $pinkColor;
}
.bgColorBlue {
  background-color: $secondaryColor;
}
.bgColorPurple {
  background-color: $purpleColor;
}
.bgColorPink {
  background-color: $pinkColor;
}
.backColorBlue {
  &::after {
    content: "";
    height: 50%;
    width: 102%;
    position: absolute;
    bottom: 1%;
    left: -2px;
    opacity: 0.5;
    background-color: var(--secondaryColor);
    z-index: -1;
  }
}
.backColorPurple {
  &::after {
    content: "";
    height: 50%;
    width: 108%;
    position: absolute;
    bottom: 1%;
    left: -2px;
    opacity: 0.8;
    background-color: var(--purpleColor);
    z-index: -1;
  }
}
.backColorPink {
  &::after {
    content: "";
    height: 50%;
    width: 108%;
    position: absolute;
    bottom: 1%;
    left: -2px;
    opacity: 0.8;
    background-color: var(--pinkColor);
    z-index: -1;
  }
}
.visible {
  display: inherit;
}
@keyframes imgAnimationPerso {
  100% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(5px, -15px, 0);
  }
}
@keyframes imgAnimationPro {
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (max-width: 767px) {
  .backColorBlue,
  .backColorPink,
  .backColorPurple {
    &::after {
      height: 100%;
      opacity: 0.5;
    }
  }
}
@media screen and (max-width: 500px) {
  .backColorBlue,
  .backColorPink,
  .backColorPurple {
    &::after {
      height: 50%;
      opacity: 0.8;
    }
  }
}
