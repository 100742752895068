.pagePro {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  margin-top: 50px;
  .experiencePro {
    grid-column: 1;
    justify-self: center;
    .experiencesProTitre {
      margin-bottom: 20px;
      .spanNameTrace {
        display: inline-block;
        position: relative;
        z-index: 1;
        .traceTitreEP {
          position: absolute;
          bottom: 5px;
          left: -5px;
          z-index: -1;
        }
      }
    }
  }
  .diplomes {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 65px auto 15% 15%;
    grid-column: 2;
    justify-self: center;
    .diplomeFormationTitre {
      margin-bottom: 20px;
      .spanNameTrace {
        display: inline-block;
        position: relative;
        z-index: 1;
        .traceTitreDiplome {
          position: absolute;
          bottom: 5px;
          left: -15px;
          z-index: -1;
        }
      }
    }
    .buttonContactCv {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-direction: row;
    }
    .traitTitre4 {
      justify-self: center;
      width: 30%;
      animation: imgAnimationPro 1.5s ease-in-out infinite alternate;
      transform: rotate(20deg);
    }
  }
  .competencesTechniquesTitre {
    width: 60%;
    justify-self: center;
    margin-top: 40px;
    margin-bottom: 40px;
    .spanNameTrace {
      display: inline-block;
      position: relative;
      z-index: 1;
      .traceTitreComp {
        position: absolute;
        bottom: 5px;
        left: -10px;
        z-index: -1;
      }
    }
  }
  .competencesTechniques {
    display: grid;
    row-gap: 50px;
    grid-column: 1/3;
    margin-bottom: 40px;
    .compDev {
      width: 60%;
      line-height: 1;
      z-index: 1;
      grid-column: 1;
      grid-row: 1;
      justify-self: center;
      > h5 {
        display: inline-block;
        position: relative;
        &::after {
          content: "";
          height: 50%;
          width: 102%;
          position: absolute;
          bottom: 1%;
          left: -2px;
          opacity: 0.8;
          background-color: var(--pinkColor);
          z-index: -1;
        }
      }
    }
    .competencesTechniquesIconesDev {
      display: grid;
      row-gap: 15px;
      column-gap: 15px;
      grid-template-columns: repeat(4, 100px);
      grid-row: 2;
      justify-self: center;
      .iconeCompetences {
        width: 50px;
        height: 50px;
      }
    }
    .compGraph {
      width: 60%;
      line-height: 1;
      z-index: 1;
      grid-column: 2;
      justify-self: center;
      > h5 {
        display: inline-block;
        position: relative;
        &::after {
          content: "";
          height: 50%;
          width: 102%;
          position: absolute;
          bottom: 1%;
          left: -2px;
          opacity: 0.8;
          background-color: var(--pinkColor);
          z-index: -1;
        }
      }
    }
  }
  .competencesTechniquesIconesGraph {
    display: grid;
    column-gap: 15px;
    grid-template-columns: repeat(4, 100px);
    grid-row: 2;
    grid-column: 2;
    justify-self: center;
    .iconeCompetences {
      width: 50px;
      height: 50px;
    }
  }
}
@media screen and (max-width: 992px) {
  .pagePro {
    grid-template-columns: 50% 50%;
    .experiencePro {
      grid-column: 1;
      width: 90%;
      justify-self: center;
    }
    .diplomes {
      grid-column: 2;
      width: 90%;
      justify-self: center;
    }
    .competencesTechniquesTitre {
      width: 100%;
      margin-left: 35px;
      grid-column: 1;
      grid-row: 2;
    }
    .competencesTechniques {
      grid-column: 1/3;
      .compDev {
        justify-self: flex-start;
        margin-left: 35px;
      }
      .competencesTechniquesIconesDev {
        justify-self: unset;
        margin-left: 35px;
      }
      .compGraph {
        justify-self: unset;
        margin-left: 20px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .pagePro {
    grid-template-columns: 100%;
    .experiencePro {
      width: unset;
    }
    .diplomes {
      grid-template-rows: 65px auto auto auto;
      grid-column: 1;
      grid-row: 2;
      margin-top: 20px;
      width: unset;
      row-gap: 15px;
      .buttonContactCv {
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 30px;
        flex-direction: row;
      }
      .traitTitre4 {
        padding-top: 20px;
        align-self: end;
        width: 30%;
      }
    }
    .competencesTechniquesTitre {
      width: unset;
      margin: 40px auto;
      grid-row: 3;
    }
    .competencesTechniques {
      display: block;
      grid-row: unset;
      grid-column: 1;
      width: unset;
      margin: auto;
      .compDev {
        display: block;
        grid-row: 4;
        margin-bottom: 20px;
      }
      .competencesTechniquesIconesDev {
        margin: 0;
        margin-bottom: 30px;
      }
      .compGraph {
        display: block;
        grid-row: 6;
        margin-bottom: 20px;
      }
      .competencesTechniquesIconesGraph {
        row-gap: 15px;
        margin-bottom: 50px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .pagePro {
    align-self: center;
    grid-template-columns: 100vw;
    justify-self: center;
    .experiencePro {
      width: 90%;
    }
    .diplomes {
      width: 90%;
    }
    .competencesTechniquesTitre {
      width: 90%;
    }
    .competencesTechniques {
      display: block;
      grid-row: unset;
      grid-column: 1;
      width: 90%;
      margin: auto;
      .compDev {
        display: block;
        grid-row: 4;
        margin-bottom: 20px;
      }
      .competencesTechniquesIconesDev {
        grid-template-columns: repeat(4, auto);
        margin: 0;
        margin-bottom: 30px;
        .iconeCompetences {
          justify-self: center;
        }
      }
      .compGraph {
        display: block;
        grid-row: 6;
        margin-bottom: 20px;
      }
      .competencesTechniquesIconesGraph {
        grid-template-columns: repeat(4, auto);
        .iconeCompetences {
          justify-self: center;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .pagePro {
    .competencesTechniques {
      .competencesTechniquesIconesDev {
        grid-template-columns: repeat(3, auto);
        margin: 0;
        margin-bottom: 30px;
      }
      .competencesTechniquesIconesGraph {
        grid-template-columns: repeat(3, auto);
      }
    }
  }
}
