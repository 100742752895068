.pageProject {
  grid-column: 1;
  display: grid;
  grid-template-columns: 180px auto 1fr;
  grid-template-rows: 100px repeat(5, auto);
  .buttonPos {
    grid-column: 2;
    grid-row: 2;
    :hover {
      box-shadow: 0px 1px 0px 0px;
      color: var(--textColor);
      transition: ease 0.2s;
    }
  }
  .titrePageProject {
    grid-column: 2;
    grid-row: 3;
  }
  .scrollDisplay {
    z-index: 1;
    grid-column: 3;
    grid-row: 4;
    align-self: center;
    margin-left: 20px;
    > span {
      padding: 2px;
      border-radius: 15px;
      display: inline-block;
      font-family: $baloo;
      position: relative;
      background-color: var(--mainColor);
    }
    .scrollTrace {
      position: absolute;
      z-index: -1;
      bottom: -21px;
    }
  }
  .imgCouv {
    width: clamp(335px, 90vw, 1000px);
    height: 450px;
    overflow-y: scroll;
    border: solid 2px var(--textColor);
    border-radius: 15px;
    grid-column: 2;
    grid-row: 4;
    > img {
      width: 100%;
    }
  }
  .pageProjectsButton {
    grid-column: 2;
    grid-row: 5;
    display: flex;
    padding-top: 25px;
    flex-direction: row;
    gap: 30px;
  }
  .descriptionLangagesDisplay {
    max-width: 1000px;
    grid-column: 2;
    grid-row: 6;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    margin: 35px 0px;
    .titreTraitDescription {
      position: relative;
      grid-column: 1;
      grid-row: 1;
      .spanNameTrace {
        display: inline-block;
        position: relative;
        z-index: 1;
        .traitPageProjet1 {
          position: absolute;
          left: 10px;
          top: -10px;
          z-index: -1;
        }
      }
    }
    .descriptionProjet {
      width: 520px;
      text-align: justify;
      margin-top: 25px;
      grid-column: 1;
      grid-row: 2;
    }
    .titreTraitLangages {
      position: relative;
      > h5 {
        position: relative;
        z-index: 1;
      }
      > img {
        position: absolute;
        top: 30%;
      }
    }
    .iconesLangagesPageProjet {
      display: flex;
      gap: 25px;
      height: 150px;
      align-items: center;
      > img {
        width: 60px;
        height: 60px;
      }
    }
    .pageProjectsButtonJouer {
      grid-column: 1;
      display: flex;
      padding-top: 25px;
      gap: 30px;
    }
  }
  .titreTraitPictures {
    padding-top: 20px;
    position: relative;
    grid-column: 2;
    grid-row: 7;
    > h5 {
      position: relative;
      z-index: 1;
    }
    > img {
      position: absolute;
      bottom: 5%;
    }
  }
  .picturesPageProjet {
    display: grid;
    padding-top: 20px;
    grid-column: 2;
    grid-row: 8;
    grid-template-columns: auto auto;
    column-gap: 10px;
    align-items: center;
    padding-bottom: 20px;
    > img {
      width: clamp(200px, 30vw, 500px);
      height: 90%;
      border: solid 2px var(--textColor);
      border-radius: 15px;
      object-fit: fill;
    }
  }
}
@media screen and (max-width: 992px) {
  .pageProject {
    grid-column: 1;
    grid-template-columns: 100vw;
    .buttonPos {
      grid-column: 1;
      width: 90%;
      margin: auto;
    }
    .titrePageProject {
      grid-column: 1;
      width: 90%;
      margin: auto;
    }
    .scrollDisplay {
      position: absolute;
      right: 25px;
      top: 415px;
    }
    .imgCouv {
      grid-column: 1;
      justify-self: center;
    }
    .pageProjectsButton {
      grid-column: 1;
      width: 90%;
      margin: auto;
    }
    .descriptionLangagesDisplay {
      grid-column: 1;
      width: 90%;
      margin: 50px auto 0 auto;
      .pageProjectsButtonJouer {
        display: none;
      }
    }
    .titreTraitPictures {
      width: 90%;
      margin: auto;
      grid-column: 1;
      > h5 {
        position: relative;
        z-index: 1;
      }
      > img {
        position: absolute;
        bottom: 5%;
      }
    }
    .picturesPageProjet {
      width: 90%;
      margin: auto;
      grid-column: 1;
      > img {
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .pageProject {
    .descriptionLangagesDisplay {
      grid-template-columns: 60% 40%;
      column-gap: 40px;
      .descriptionProjet {
        max-width: 100%;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .pageProject {
    .scrollDisplay {
      display: none;
    }
    .imgCouv {
      width: 90%;
      height: 300px;
    }
    .descriptionLangagesDisplay {
      grid-template-columns: 100%;
      .descriptionProjet {
        margin-bottom: 25px;
      }
    }
    .picturesPageProjet {
      grid-template-columns: auto;
      width: 90%;
      margin: auto;
      justify-items: center;
      align-items: center;
      > img {
        width: 90%;
        margin: auto;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .pageProject {
      max-width: 360px;
      .buttonPos {
        max-width: 360px;
      }
      .titrePageProject {
        max-width: 360px;
      }
      .imgCouv {
        max-width: 360px;
      }
      .pageProjectsButton {
        max-width: 360px;
      }
      .descriptionLangagesDisplay {
        max-width: 360px;
      }
    }
  }
}
