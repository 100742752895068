.secondaryButtonConfig > button {
  all: unset;
  background-color: var(--mainColor);
  border: solid 1px var(--textColor);
  color: var(--textColor);
  border-radius: 15px;
  width: 120px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $balooT;
  font-weight: 500;
  &:hover {
    background-color: var(--textColor);
    color: var(--mainColor);
    transition: 0.3s all ease;
    cursor: pointer;
  }
}
@media screen and (max-width: 992px) {
  .secondaryButtonConfig > button {
    max-width: 100%;
    max-height: 100%;
  }
}
