.home {
  width: 100%;
  height: 100dvh;
  display: grid;
  grid-template-rows: 35% auto auto auto 1fr;
  justify-self: start;
  align-self: center;
  .titrePortfolio {
    margin-left: 180px;
    grid-row: 2;
    .spanNameTrace {
      display: inline-block;
      position: relative;
      z-index: 1;
      .traceMainPage {
        position: absolute;
        bottom: 5px;
        left: -5px;
        z-index: -1;
      }
    }
  }
  .sousTitrePortfolio {
    margin-left: 180px;
    grid-row: 3;
    margin-top: 20px;
    .dfjs {
      position: relative;
      z-index: 1;
      &::after {
        content: "";
        height: 25%;
        width: 103.5%;
        position: absolute;
        bottom: 20%;
        left: -2px;
        opacity: 1;
        background-color: var(--purpleColor);
        z-index: -1;
      }
    }
  }

  .divSecondaryButton {
    margin-left: 180px;
    grid-row: 4;
    display: flex;
    padding-top: 40px;
    flex-direction: row;
    gap: 30px;
  }
  .traceMainPage2 {
    grid-row: 5;
    justify-self: end;
    align-self: end;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 992px) {
  .home {
    justify-self: center;
    grid-column: 1;
    margin: 0;
    .titrePortfolio {
      grid-column: 1;
    }
    .sousTitrePortfolio {
      grid-column: 1;
    }
    .divSecondaryButton {
      grid-column: 1;
    }
  }
}
@media screen and (max-width: 767px) {
  .home {
    .titrePortfolio {
      margin-left: 100px;
    }
    .sousTitrePortfolio {
      margin-left: 100px;
    }
    .divSecondaryButton {
      margin-left: 100px;
    }
  }
}
@media screen and (max-width: 500px) {
  .home {
    grid-template-rows: 30% auto auto auto 1fr;
    .titrePortfolio {
      margin-left: 50px;
      > h1 {
        font-size: 48px;
      }
    }
    .sousTitrePortfolio {
      font-size: 25px;
      margin-left: 50px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    .divSecondaryButton {
      margin-left: 50px;
    }
  }
}
@media screen and (max-width: 360px) {
  .home {
    grid-template-rows: 20% auto auto auto 1fr;
    .titrePortfolio {
      width: 80%;
      margin: auto;
    }
    .sousTitrePortfolio {
      width: 80%;
      margin: 30px auto;
    }
    .divSecondaryButton {
      width: 80%;
      margin: auto;
      padding: 0;
      flex-direction: column;
      align-items: center;
    }
    .traceMainPage2 {
      align-self: center;
      margin: 0;
    }
  }
}
