.blocCardsDisplay {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  .listCardsDisplay {
    grid-column: 1;
    width: 950px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
    row-gap: 50px;
    justify-self: center;
    align-self: center;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 992px) {
  .blocCardsDisplay {
    max-width: 992px;
    .listCardsDisplay {
      margin: auto;
      width: 80%;
      grid-template-columns: repeat(2, auto);
    }
  }
}
@media screen and (max-width: 500px) {
  .blocCardsDisplay {
    .listCardsDisplay {
      width: 100%;
      grid-template-columns: 100%;
      row-gap: 25px;
    }
  }
}
