.projects {
  grid-column: 1;
  display: grid;
  grid-template-columns: auto auto 1fr;
  margin-left: 100px;
  margin-top: 180px;
  column-gap: 30px;
  img {
    margin-top: 30px;
    grid-column: 2;
  }
}
@media screen and (max-width: 992px) {
  .projects {
    margin-top: 120px;
    grid-column: 1;
  }
}
@media screen and (max-width: 500px) {
  .projects {
    margin-top: 80px;
    width: 100%;
    grid-template-columns: 70% 30%;
    margin-left: 0;
    column-gap: 0;
    > h1 {
      text-align: center;
      align-self: center;
      line-height: 1;
    }
    > img {
      justify-self: center;
      margin-top: 0;
    }
  }
}
@media screen and (max-width: 360px) {
  .projects {
    width: 80%;
    grid-template-columns: 1fr 1fr;
    margin: 80px auto 50px auto;
    > h1 {
      text-align: left;
      align-self: center;
      line-height: 1;
    }
    > img {
      justify-self: center;
      margin-top: 0;
    }
  }
}
