.contactFormDisplay {
  .contactFormGrid {
    display: grid;
    max-width: 450px;
    .nameForm,
    .emailForm {
      display: grid;
      font-family: $baloo;
      color: var(--textColor);
      > input {
        margin-bottom: 15px;
        height: 30px;
        padding: 5px;
        border: solid 1px var(--textColor);
        border-radius: 15px;
      }
    }
    .messageForm {
      display: grid;
      grid-template-rows: repeat(2, auto);
      font-family: $baloo;
      text-transform: capitalize;
      color: var(--textColor);
      > textarea {
        resize: none;
        height: 140px;
        padding: 5px;
        overflow: auto;
        border-radius: 15px;
      }
    }
    .sendButton {
      all: unset;
      background-color: var(--mainColor);
      border: solid 1px var(--textColor);
      color: var(--textColor);
      border-radius: 15px;
      width: 120px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $balooT;
      font-weight: 500;
      margin-top: 20px;
      &:hover {
        background-color: var(--textColor);
        color: var(--mainColor);
        transition: 0.3s all ease;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .contactFormDisplay {
    .contactFormGrid {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 360px) {
  .contactFormDisplay {
    max-width: 360px;
    .contactFormGrid {
      max-width: 360px;
    }
  }
}
